import { connectSocket } from "@/socket";
import { Socket } from "socket.io-client";
import { toRaw } from "vue";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { 
  IconDefinition, 
  faTruck, 
} from "@fortawesome/free-solid-svg-icons";
import { TicketsRoutesEnum } from "@/modules/tickets/router";

function buildIcon(fontAwesomIcon: IconDefinition, modifiers = {}) {
  return {
          path         : fontAwesomIcon.icon[4] as string,
          fillColor    : "blue",
          fillOpacity  : 1,
          strokeWeight : 1,
          strokeColor  : "#ffffff",
          scale        : 0.075,
          anchor       : new google.maps.Point(
              fontAwesomIcon.icon[0] / 2, // width
              fontAwesomIcon.icon[1]      // height
          ),
          ...(modifiers || {})
  }
}

@Options({})
export default class LiveViewPage extends Vue {
  @Prop() readonly propName!: String;

  socket: Socket = null;

  trucksOnMap: any[] = [];

  mapRef: google.maps.Map = null;

  markers: google.maps.Marker[] = [];

  get map() {
    return toRaw(this.mapRef);
  }

  private _buildMap(element: HTMLElement, bounds?: google.maps.LatLngBounds) {

    this.mapRef = new google.maps.Map(
      element,
      {
        center: this.$config.startCenter,
        zoom: this.$config.startZoom,
      },

    );

    if (bounds) {
      this.map.fitBounds(bounds, 0);
    }

    this.map.data.setStyle({
      strokeWeight: 1,
      strokeOpacity: 1,
      strokeColor: "#3399FF",
      fillColor: "#3399FF",
      fillOpacity: 0.2,
      editable: false,
      draggable: false,
      clickable: true,
      zIndex: 1,
    });
  }

  private updateBounds() {
    const bounds = new google.maps.LatLngBounds();

    this.trucksOnMap.forEach(({ marker }) => {
      bounds.extend(marker.getPosition());
    });

    this.map.fitBounds(bounds, 100);
  }

  get hasMarkers(){
    return this.trucksOnMap.length
  }

  private updateTrucks(msg) {
    const wasEmpty = !this.trucksOnMap?.length;
    const truckId = msg.id;

    const truck = this.trucksOnMap?.find(t => t.id === truckId);

    const position = new google.maps.LatLng({
      lat: msg.data.lat, lng: msg.data.lng
    });

    if (!truck) {
      const marker = new google.maps.Marker({
        position,
        map: this.map,
        icon: buildIcon(faTruck)
      });

      marker.addListener('click', () => {
        const routeData = this.$router.resolve({
          name: TicketsRoutesEnum.TICKETS_DETAIL,
          params: { ticketId: msg.data.ticket_id }
        });
        window.open(routeData.href, '_blank');
      });

      this.trucksOnMap.push({
        id: truckId,
        name: `Truck_${truckId}`,
        createdAt: msg.createdAt,
        lat: msg.data.lat,
        lng: msg.data.lng,
        marker,
      });

    } else {
      truck.createdAt = msg.createdAt;
      truck.lat = msg.data.lat;
      truck.lng = msg.data.lng;

      truck.marker.setPosition(position);
    }

    wasEmpty && this.updateBounds();
  }

  private _initSocket() {
    this.socket = connectSocket("/mysocket");

    this.socket.on('position', (msg) => {
      console.debug("msg", msg);
      this.updateTrucks(msg);
    });

    this.socket.connect();
  }

  created() {
    this._initSocket();
  }

  mounted() {
    this._buildMap((this.$refs.mapEl as HTMLElement));
  }

  beforeUnmount() {
    this.socket.disconnect();
  }
}