import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-2302dc4e")
const _hoisted_1 = { class: "page" }
const _hoisted_2 = { class: "map" }
const _hoisted_3 = {
  id: "w3_map",
  ref: "mapEl",
  class: "p-mt-3"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.$t('god_mode.title')), 1),
    _createElementVNode("section", _hoisted_2, [
      (_ctx.hasMarkers)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 0,
            type: "button",
            icon: "pi pi-search",
            label: "Zoom all pins",
            class: "rounded-btn raised-btn",
            onClick: _ctx.updateBounds
          }, null, 8, ["onClick"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, null, 512)
    ])
  ]))
}